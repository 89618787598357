import { Vue, Prop, Component } from 'vue-property-decorator';
import { Action, State as ClassState, Getter } from 'vuex-class';
import { State, StateSlice } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import NetValue from '@/components/common/net-value/netValue.vue';
import { formatNumber } from '@/filters/number';
import BigNumber from 'bignumber.js';
import { LoanFirestore } from '@/store/models/Loan';

@Component({
  components: {
    WithDownloads,
    FundDocs,
    NetValue,
  },
})

export default class PropertyInfo extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Prop({ default: (): boolean => false }) userLoggedIn!: boolean;
  @Action openModal!: Function;
  @ClassState user!: State['user'];
  @ClassState premiumLoans!: StateSlice<LoanFirestore[]>;
  @ClassState identificationRequest!: State['identificationRequest'];
  @Getter isInvestor!: boolean;
  @Getter getPremiumAssetsTotalAmount!: number;

  formatNumber = formatNumber;
  BigNumber = BigNumber
  treatAsPremium = true; // for rax we hide some information, and use the some of the same logic as for premium assets

  alternativeTextAssetIds: string[] = ['Wf0xVE62U5DxGnU1sRwc'];

  get assetId(): string {
    return this.$route.params.id;
  }

  get assetAddress(): string {
    return this.asset ? this.asset.city : '';
  }

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest ? this.identificationRequest?.status === IdentificationRequestStatus.Initial : true;
  }

  get amountInvestedLoans(): number {
    return this.premiumLoans.payload?.length;
  }

  shouldShowAlternativeText(assetId: string): boolean {
    return this.alternativeTextAssetIds.includes(assetId);
  }
}
